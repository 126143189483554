import { PaymentMethodIconsBS } from "@style-variables";
import PaymentMethods from "./PaymentMethods";

export default pageParams => {
  const { imgParams, pathfinder, i18n } = pageParams;

  const _i18n = i18n.pages.HeaderRotaryBanner;

  const items = _i18n.items.map(item => ({
    title: item.TITLE,
    icon: item.ICON,
    iconColor: item.ICON_COLOR,
    img: item.IMG
      ? {
          src: imgParams.utils.get(imgParams.svgDir, "/" + item.IMG),
          cloudinary: imgParams.cloudinary,
          sizes: {
            desktop: 92,
            tablet: 102,
            mobile: 102
          },
          aspect: 0.28
        }
      : null,
    url:
      !item.URL || item.URL.indexOf("http") !== -1
        ? item.URL
        : pathfinder.get(item.URL)
  }));

  if (_i18n.INCLUDE_PAYMENT_METHODS) {
    const paymentMethods = PaymentMethods(pageParams);

    items.push({
      items: paymentMethods,
      className: PaymentMethodIconsBS
    });
  }

  return {
    enabled: _i18n.ENABLED,
    includePaymentMethods: _i18n.INCLUDE_PAYMENT_METHODS,
    justifyContent: _i18n.JUSTIFY_CONTENT,
    position: _i18n.POSITION,
    className: _i18n.CSS_CLASS,
    items
  };
};
